import React, {useEffect} from "react";
import {BrowserRouter as Router, Redirect, Route, RouteProps, Switch, useHistory} from "react-router-dom";
import Login from "./Login";
import NotFound from "./NotFound";
import {ProvideSnackbar} from "./context/SnackbarProvider";
import {useAuth} from "./context/AuthContext";
import {ProvideAuth} from "./context/AuthProvider";
import {Routes} from "./context/routes";
import Projects from "./Projects";
import ProjectCards from "./ProjectCards";
import { CircularProgress } from "@material-ui/core";

export default function Init() {
    return (
            <Router>
                <ProvideAuth>
                    <ProvideSnackbar>
                <div>
                    <Switch>
                        <Route exact path="/">
                            <CheckAuth />
                        </Route>
                        <Route path={Routes.Login}>
                            <Login />
                        </Route>
                        <PrivateRoute exact path={Routes.Projects}>
                            <Projects />
                        </PrivateRoute>
                        <PrivateRoute path={Routes.ProjectCards}>
                            <ProjectCards />
                        </PrivateRoute>
                        <Route path="*">
                            <NotFound />
                        </Route>
                    </Switch>
                </div>
                    </ProvideSnackbar>
                </ProvideAuth>
            </Router>

    );
}


const CheckAuth: React.FC<RouteProps> = () => {
    const auth = useAuth();
    const history = useHistory()

    useEffect(() => auth?.account ? history.replace(Routes.Projects) : history.replace(Routes.Login), [auth, history])

    return <CircularProgress />
}

const PrivateRoute: React.FC<RouteProps> = ({ children, ...rest }) => {
    let auth = useAuth();
    return (
        <Route
            {...rest}
            render={({ location }) =>
                auth?.account ? (
                    children
                ) : (
                    <Redirect
                        to={{
                            pathname: Routes.Login,
                            state: { from: location }
                        }}
                    />
                )
            }
        />
    );
}
