import React from 'react';
import {createStyles, makeStyles, Theme} from '@material-ui/core/styles';
import ArrowRight from '@material-ui/icons/ArrowRight';
import {ColDef, DataGrid} from '@material-ui/data-grid';
import {
    Card,
    Label,
    useCardSetLabelMutation,
    useProjectCardsQuery
} from "./generated/graphql";
import {isQueryLoaded} from "./utils";
import {Breadcrumbs, Paper, Button, ButtonGroup, DialogContentText, Link, Typography} from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';
import {AlertDialog} from './components/AlertDialog';
import {useSnackbar} from "./context/SnackbarContext";
import { useHistory } from 'react-router-dom';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            width: '100%',
            height: 800,
        },
        breadcrumbs: {
            margin: theme.spacing(1),
        },
        paper: {
            width: '100%',
            marginBottom: theme.spacing(2),
        },    button: {
            margin: theme.spacing(1),
        },
    }),
);

const Test: React.FC<{ projectId: string }> = ({ projectId }) => {
    const classes = useStyles();
    const history = useHistory();
    const [showAlert, setShowAlert] = React.useState(false)
    const [selectedCard, setSelectedCard] = React.useState<Card | null>(null)
    const [selectedLabel, setSelectedLabel] = React.useState<Label | null>(null)
    const { setMessage, removeMessage } = useSnackbar()
    const query = useProjectCardsQuery({
        variables: {
            id: projectId,
        }
    })

    const [setLabel, { loading }] = useCardSetLabelMutation({
        onCompleted: ({ cardSetLabel }) => {
            if (cardSetLabel.__typename === 'Card') {
                setMessage(`Der Test #${cardSetLabel.number} wurde auf Status "${cardSetLabel.label?.name}" gesetzt.`, 'success')
                setSelectedCard(null);
                setSelectedLabel(null);
                setShowAlert(false);
                return
            }
            if (cardSetLabel.__typename === 'ValidationErrors') {
                setMessage(cardSetLabel)
                return
            }

        },
        onError: setMessage,
    })

    const project = isQueryLoaded(query) ? query.data.project : null
    const rows: Array<Card> = project ? project.cards.list : []

    const columns: ColDef[] = [
        { field: 'number', headerName: 'Number', width: 200 },
        { field: 'code', headerName: 'Code', width: 200 },
        { field: 'updatedAt', headerName: 'Zuletzt bearbeitet', width: 200,
            valueFormatter: (c) => {
                const card = c.row as Card
                return card.createdAt === card.updatedAt ? '-' : new Date(card.updatedAt).toLocaleString()
            },
        },
        {
            field: 'label',
            headerName: 'Status',
            description: 'Test Status',
            sortable: false,
            width: 200,
            renderCell: (c) => {
                return <Button
                    variant="contained"
                    color="primary"
                    style={{
                        backgroundColor: c.row.label ? c.row.label.color : undefined,
                    }}
                    onClick={() => {
                        const card = c.row as Card
                        setSelectedCard(card)
                        if (card.label) {
                            setSelectedLabel(card.label)
                        }
                        setShowAlert(true)
                    }}
                    endIcon={<ArrowRight />}
                >
                    {c.row.label ? c.row.label.name : ''}
                </Button>
            }
        },
    ];

    return <div className={classes.root}>
        {project && <AlertDialog
            onClose={() => setShowAlert(false)} open={showAlert} title={selectedCard ? `Test #${selectedCard.number}` : ''} buttons={[{
            color: "secondary",
            title: 'Abbrechen',
            onClick: () => setShowAlert(false),
        },
            {
                color: "primary",
                title: 'Bestätigen',
                disabled: loading,
                onClick: () => {
                    if (selectedCard && selectedLabel) {
                        removeMessage()
                        setLabel({ variables: { args: { label: selectedLabel.id, card: selectedCard.id }}})
                    }
                }
            }
        ]} >
            <ButtonGroup>
                {
                    project.labels.map(l => <Button
                        key={l.id}
                        variant="contained"
                        onClick={() => setSelectedLabel(l)} style={selectedLabel && selectedLabel.id === l.id && l.color ? { color: '#FFFFFF', backgroundColor: l.color } : undefined}>{l.name}</Button>)
                }
            </ButtonGroup>
            {selectedLabel && <DialogContentText><p>{selectedLabel.description}</p></DialogContentText>}
            {selectedCard?.label?.changeWarning && <Alert severity="warning">{selectedCard?.label?.changeWarning}</Alert>}
        </AlertDialog>}
        <Breadcrumbs className={classes.breadcrumbs} aria-label="breadcrumb">
          <Link color="inherit" href="/" onClick={(e: React.MouseEvent) => { e.preventDefault(); history.goBack() }}>
            Alle Testtage
          </Link>
          <Typography color="textPrimary">{project?.name || 'Laden' }</Typography>
        </Breadcrumbs>
        <Paper className={classes.paper}>
            <DataGrid autoHeight rows={rows} columns={columns} pageSize={100} />
        </Paper>
    </div>
}

export default Test