import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  /** A date-time string at UTC, such as 2019-12-03T09:54:33Z, compliant with the date-time format. */
  DateTime: any;
};

export type ValidationError = {
  __typename?: 'ValidationError';
  code: Scalars['String'];
  message: Scalars['String'];
};

export type Account = {
  __typename?: 'Account';
  id: Scalars['ID'];
  name: Scalars['String'];
  projects: ProjectList;
};

export type Project = {
  __typename?: 'Project';
  id: Scalars['ID'];
  name: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  labels: Array<Label>;
  cards: CardList;
};

export type ProjectList = {
  __typename?: 'ProjectList';
  list: Array<Project>;
};

export type UploadLink = {
  __typename?: 'UploadLink';
  url: Scalars['String'];
  path: Scalars['String'];
};

export type Card = {
  __typename?: 'Card';
  id: Scalars['ID'];
  number: Scalars['Int'];
  code: Scalars['String'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  label?: Maybe<Label>;
};


export type CardList = {
  __typename?: 'CardList';
  list: Array<Card>;
};

export type Label = {
  __typename?: 'Label';
  id: Scalars['ID'];
  name: Scalars['String'];
  title: Scalars['String'];
  description: Scalars['String'];
  color?: Maybe<Scalars['String']>;
  changeWarning?: Maybe<Scalars['String']>;
};

export type Query = {
  __typename?: 'Query';
  account: Account;
  cardByCode?: Maybe<Card>;
  card: Card;
  project: Project;
};


export type QueryCardByCodeArgs = {
  code: Scalars['String'];
};


export type QueryCardArgs = {
  id: Scalars['ID'];
};


export type QueryProjectArgs = {
  id: Scalars['ID'];
};

export type Mutation = {
  __typename?: 'Mutation';
  accountLogin: AuthenticatorResult;
  accountLogout: Scalars['Boolean'];
  accountCreate: AuthenticatorResult;
  createUploadLink: UploadLink;
  cardSetLabel: CardResult;
  cardCreate: CardListResult;
  projectCreate: ProjectResult;
  projectDownloadProjectCards: FileResult;
  labelCreate: LabelResult;
  setDefaultLabel: LabelResult;
};


export type MutationAccountLoginArgs = {
  AccountLoginArgs: AccountLoginArgs;
};


export type MutationAccountCreateArgs = {
  AccountCreateArgs: AccountCreateArgs;
};


export type MutationCreateUploadLinkArgs = {
  name: Scalars['String'];
};


export type MutationCardSetLabelArgs = {
  CardSetLabelArgs: CardSetLabelArgs;
};


export type MutationCardCreateArgs = {
  CardCreateArgs: CardCreateArgs;
};


export type MutationProjectCreateArgs = {
  ProjectCreateArgs: ProjectCreateArgs;
};


export type MutationProjectDownloadProjectCardsArgs = {
  id: Scalars['ID'];
};


export type MutationLabelCreateArgs = {
  LabelCreateArgs: LabelCreateArgs;
};


export type MutationSetDefaultLabelArgs = {
  LabelSetDefaultArgs: LabelSetDefaultArgs;
};

export type AuthenticatorResult = Authenticator | ValidationErrors;

export type Authenticator = {
  __typename?: 'Authenticator';
  token: Scalars['String'];
  account: Account;
};

export type ValidationErrors = {
  __typename?: 'ValidationErrors';
  errors: Array<ValidationError>;
};

export type AccountLoginArgs = {
  identifier: Scalars['String'];
  password: Scalars['String'];
};

export type AccountCreateArgs = {
  name: Scalars['String'];
  password: Scalars['String'];
};

export type CardResult = Card | ValidationErrors;

export type CardSetLabelArgs = {
  card: Scalars['ID'];
  label: Scalars['ID'];
};

export type CardListResult = CardList | ValidationErrors;

export type CardCreateArgs = {
  project: Scalars['ID'];
  count: Scalars['Int'];
};

export type ProjectResult = Project | ValidationErrors;

export type ProjectCreateArgs = {
  name: Scalars['String'];
  description?: Maybe<Scalars['String']>;
};

export type FileResult = File | ValidationErrors;

export type File = {
  __typename?: 'File';
  id: Scalars['ID'];
  name: Scalars['String'];
  url: Scalars['String'];
};

export type LabelResult = Label | ValidationErrors;

export type LabelCreateArgs = {
  name: Scalars['String'];
  title: Scalars['String'];
  description: Scalars['String'];
  sort: Scalars['Int'];
  color?: Maybe<Scalars['String']>;
  project: Scalars['ID'];
};

export type LabelSetDefaultArgs = {
  label: Scalars['ID'];
};

export type CardFragment = (
  { __typename?: 'Card' }
  & Pick<Card, 'id' | 'number' | 'code' | 'updatedAt' | 'createdAt'>
  & { label?: Maybe<(
    { __typename?: 'Label' }
    & LabelFragment
  )> }
);

export type ProjectFragment = (
  { __typename?: 'Project' }
  & Pick<Project, 'id' | 'name' | 'description'>
  & { labels: Array<(
    { __typename?: 'Label' }
    & LabelFragment
  )> }
);

export type ValidationErrorsFragment = (
  { __typename?: 'ValidationErrors' }
  & { errors: Array<(
    { __typename?: 'ValidationError' }
    & Pick<ValidationError, 'code' | 'message'>
  )> }
);

export type LabelFragment = (
  { __typename?: 'Label' }
  & Pick<Label, 'id' | 'color' | 'description' | 'title' | 'name' | 'changeWarning'>
);

export type FileFragment = (
  { __typename?: 'File' }
  & Pick<File, 'id' | 'name' | 'url'>
);

export type AccountFragment = (
  { __typename?: 'Account' }
  & Pick<Account, 'id' | 'name'>
);

export type AuthenticatorFragment = (
  { __typename?: 'Authenticator' }
  & Pick<Authenticator, 'token'>
  & { account: (
    { __typename?: 'Account' }
    & AccountFragment
  ) }
);

export type AccountCreateMutationVariables = Exact<{
  args: AccountCreateArgs;
}>;


export type AccountCreateMutation = (
  { __typename?: 'Mutation' }
  & { accountCreate: (
    { __typename?: 'Authenticator' }
    & AuthenticatorFragment
  ) | (
    { __typename?: 'ValidationErrors' }
    & ValidationErrorsFragment
  ) }
);

export type AccountLogoutMutationVariables = Exact<{ [key: string]: never; }>;


export type AccountLogoutMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'accountLogout'>
);

export type AccountLoginMutationVariables = Exact<{
  args: AccountLoginArgs;
}>;


export type AccountLoginMutation = (
  { __typename?: 'Mutation' }
  & { accountLogin: (
    { __typename?: 'Authenticator' }
    & AuthenticatorFragment
  ) | (
    { __typename?: 'ValidationErrors' }
    & ValidationErrorsFragment
  ) }
);

export type ProjectCreateMutationVariables = Exact<{
  args: ProjectCreateArgs;
}>;


export type ProjectCreateMutation = (
  { __typename?: 'Mutation' }
  & { projectCreate: (
    { __typename?: 'Project' }
    & ProjectFragment
  ) | { __typename?: 'ValidationErrors' } }
);

export type ProjectDownloadProjectCardsMutationVariables = Exact<{
  id: Scalars['ID'];
}>;


export type ProjectDownloadProjectCardsMutation = (
  { __typename?: 'Mutation' }
  & { projectDownloadProjectCards: (
    { __typename?: 'File' }
    & FileFragment
  ) | (
    { __typename?: 'ValidationErrors' }
    & ValidationErrorsFragment
  ) }
);

export type LabelCreateMutationVariables = Exact<{ [key: string]: never; }>;


export type LabelCreateMutation = (
  { __typename?: 'Mutation' }
  & { labelCreate: (
    { __typename?: 'Label' }
    & Pick<Label, 'id' | 'name'>
  ) | (
    { __typename?: 'ValidationErrors' }
    & ValidationErrorsFragment
  ) }
);

export type CardCreateMutationVariables = Exact<{ [key: string]: never; }>;


export type CardCreateMutation = (
  { __typename?: 'Mutation' }
  & { cardCreate: (
    { __typename?: 'CardList' }
    & { list: Array<(
      { __typename?: 'Card' }
      & Pick<Card, 'number' | 'code'>
    )> }
  ) | (
    { __typename?: 'ValidationErrors' }
    & ValidationErrorsFragment
  ) }
);

export type AccountProjectsQueryVariables = Exact<{ [key: string]: never; }>;


export type AccountProjectsQuery = (
  { __typename?: 'Query' }
  & { account: (
    { __typename?: 'Account' }
    & { projects: (
      { __typename?: 'ProjectList' }
      & { list: Array<(
        { __typename?: 'Project' }
        & ProjectFragment
      )> }
    ) }
  ) }
);

export type ProjectCardsQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type ProjectCardsQuery = (
  { __typename?: 'Query' }
  & { project: (
    { __typename?: 'Project' }
    & { cards: (
      { __typename?: 'CardList' }
      & { list: Array<(
        { __typename?: 'Card' }
        & CardFragment
      )> }
    ) }
    & ProjectFragment
  ) }
);

export type CardQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type CardQuery = (
  { __typename?: 'Query' }
  & { card: (
    { __typename?: 'Card' }
    & Pick<Card, 'number' | 'code'>
  ) }
);

export type CardByCodeQueryVariables = Exact<{
  code: Scalars['String'];
}>;


export type CardByCodeQuery = (
  { __typename?: 'Query' }
  & { cardByCode?: Maybe<(
    { __typename?: 'Card' }
    & CardFragment
  )> }
);

export type CardSetLabelMutationVariables = Exact<{
  args: CardSetLabelArgs;
}>;


export type CardSetLabelMutation = (
  { __typename?: 'Mutation' }
  & { cardSetLabel: (
    { __typename?: 'Card' }
    & CardFragment
  ) | (
    { __typename?: 'ValidationErrors' }
    & ValidationErrorsFragment
  ) }
);

export type AccountQueryVariables = Exact<{ [key: string]: never; }>;


export type AccountQuery = (
  { __typename?: 'Query' }
  & { account: (
    { __typename?: 'Account' }
    & AccountFragment
  ) }
);

export const LabelFragmentDoc = gql`
    fragment Label on Label {
  id
  color
  description
  title
  name
  changeWarning
}
    `;
export const CardFragmentDoc = gql`
    fragment Card on Card {
  id
  number
  code
  label {
    ...Label
  }
  updatedAt
  createdAt
}
    ${LabelFragmentDoc}`;
export const ProjectFragmentDoc = gql`
    fragment Project on Project {
  id
  name
  description
  labels {
    ...Label
  }
}
    ${LabelFragmentDoc}`;
export const ValidationErrorsFragmentDoc = gql`
    fragment ValidationErrors on ValidationErrors {
  errors {
    code
    message
  }
}
    `;
export const FileFragmentDoc = gql`
    fragment File on File {
  id
  name
  url
}
    `;
export const AccountFragmentDoc = gql`
    fragment Account on Account {
  id
  name
}
    `;
export const AuthenticatorFragmentDoc = gql`
    fragment Authenticator on Authenticator {
  token
  account {
    ...Account
  }
}
    ${AccountFragmentDoc}`;
export const AccountCreateDocument = gql`
    mutation accountCreate($args: AccountCreateArgs!) {
  accountCreate(AccountCreateArgs: $args) {
    ... on Authenticator {
      ...Authenticator
    }
    ... on ValidationErrors {
      ...ValidationErrors
    }
  }
}
    ${AuthenticatorFragmentDoc}
${ValidationErrorsFragmentDoc}`;
export type AccountCreateMutationFn = Apollo.MutationFunction<AccountCreateMutation, AccountCreateMutationVariables>;
export function useAccountCreateMutation(baseOptions?: Apollo.MutationHookOptions<AccountCreateMutation, AccountCreateMutationVariables>) {
        return Apollo.useMutation<AccountCreateMutation, AccountCreateMutationVariables>(AccountCreateDocument, baseOptions);
      }
export type AccountCreateMutationHookResult = ReturnType<typeof useAccountCreateMutation>;
export type AccountCreateMutationResult = Apollo.MutationResult<AccountCreateMutation>;
export type AccountCreateMutationOptions = Apollo.BaseMutationOptions<AccountCreateMutation, AccountCreateMutationVariables>;
export const AccountLogoutDocument = gql`
    mutation accountLogout {
  accountLogout
}
    `;
export type AccountLogoutMutationFn = Apollo.MutationFunction<AccountLogoutMutation, AccountLogoutMutationVariables>;
export function useAccountLogoutMutation(baseOptions?: Apollo.MutationHookOptions<AccountLogoutMutation, AccountLogoutMutationVariables>) {
        return Apollo.useMutation<AccountLogoutMutation, AccountLogoutMutationVariables>(AccountLogoutDocument, baseOptions);
      }
export type AccountLogoutMutationHookResult = ReturnType<typeof useAccountLogoutMutation>;
export type AccountLogoutMutationResult = Apollo.MutationResult<AccountLogoutMutation>;
export type AccountLogoutMutationOptions = Apollo.BaseMutationOptions<AccountLogoutMutation, AccountLogoutMutationVariables>;
export const AccountLoginDocument = gql`
    mutation accountLogin($args: AccountLoginArgs!) {
  accountLogin(AccountLoginArgs: $args) {
    ... on Authenticator {
      ...Authenticator
    }
    ... on ValidationErrors {
      ...ValidationErrors
    }
  }
}
    ${AuthenticatorFragmentDoc}
${ValidationErrorsFragmentDoc}`;
export type AccountLoginMutationFn = Apollo.MutationFunction<AccountLoginMutation, AccountLoginMutationVariables>;
export function useAccountLoginMutation(baseOptions?: Apollo.MutationHookOptions<AccountLoginMutation, AccountLoginMutationVariables>) {
        return Apollo.useMutation<AccountLoginMutation, AccountLoginMutationVariables>(AccountLoginDocument, baseOptions);
      }
export type AccountLoginMutationHookResult = ReturnType<typeof useAccountLoginMutation>;
export type AccountLoginMutationResult = Apollo.MutationResult<AccountLoginMutation>;
export type AccountLoginMutationOptions = Apollo.BaseMutationOptions<AccountLoginMutation, AccountLoginMutationVariables>;
export const ProjectCreateDocument = gql`
    mutation projectCreate($args: ProjectCreateArgs!) {
  projectCreate(ProjectCreateArgs: $args) {
    ... on Project {
      ...Project
    }
  }
}
    ${ProjectFragmentDoc}`;
export type ProjectCreateMutationFn = Apollo.MutationFunction<ProjectCreateMutation, ProjectCreateMutationVariables>;
export function useProjectCreateMutation(baseOptions?: Apollo.MutationHookOptions<ProjectCreateMutation, ProjectCreateMutationVariables>) {
        return Apollo.useMutation<ProjectCreateMutation, ProjectCreateMutationVariables>(ProjectCreateDocument, baseOptions);
      }
export type ProjectCreateMutationHookResult = ReturnType<typeof useProjectCreateMutation>;
export type ProjectCreateMutationResult = Apollo.MutationResult<ProjectCreateMutation>;
export type ProjectCreateMutationOptions = Apollo.BaseMutationOptions<ProjectCreateMutation, ProjectCreateMutationVariables>;
export const ProjectDownloadProjectCardsDocument = gql`
    mutation ProjectDownloadProjectCards($id: ID!) {
  projectDownloadProjectCards(id: $id) {
    ... on File {
      ...File
    }
    ... on ValidationErrors {
      ...ValidationErrors
    }
  }
}
    ${FileFragmentDoc}
${ValidationErrorsFragmentDoc}`;
export type ProjectDownloadProjectCardsMutationFn = Apollo.MutationFunction<ProjectDownloadProjectCardsMutation, ProjectDownloadProjectCardsMutationVariables>;
export function useProjectDownloadProjectCardsMutation(baseOptions?: Apollo.MutationHookOptions<ProjectDownloadProjectCardsMutation, ProjectDownloadProjectCardsMutationVariables>) {
        return Apollo.useMutation<ProjectDownloadProjectCardsMutation, ProjectDownloadProjectCardsMutationVariables>(ProjectDownloadProjectCardsDocument, baseOptions);
      }
export type ProjectDownloadProjectCardsMutationHookResult = ReturnType<typeof useProjectDownloadProjectCardsMutation>;
export type ProjectDownloadProjectCardsMutationResult = Apollo.MutationResult<ProjectDownloadProjectCardsMutation>;
export type ProjectDownloadProjectCardsMutationOptions = Apollo.BaseMutationOptions<ProjectDownloadProjectCardsMutation, ProjectDownloadProjectCardsMutationVariables>;
export const LabelCreateDocument = gql`
    mutation labelCreate {
  labelCreate(
    LabelCreateArgs: {name: "Postiroc", title: "Testergebnis: Negavtive", description: "asd", sort: 0, color: "#FFFFFF", project: "0582fee7-aadb-494c-b779-a0dcafab4c2d"}
  ) {
    ... on Label {
      id
      name
    }
    ... on ValidationErrors {
      ...ValidationErrors
    }
  }
}
    ${ValidationErrorsFragmentDoc}`;
export type LabelCreateMutationFn = Apollo.MutationFunction<LabelCreateMutation, LabelCreateMutationVariables>;
export function useLabelCreateMutation(baseOptions?: Apollo.MutationHookOptions<LabelCreateMutation, LabelCreateMutationVariables>) {
        return Apollo.useMutation<LabelCreateMutation, LabelCreateMutationVariables>(LabelCreateDocument, baseOptions);
      }
export type LabelCreateMutationHookResult = ReturnType<typeof useLabelCreateMutation>;
export type LabelCreateMutationResult = Apollo.MutationResult<LabelCreateMutation>;
export type LabelCreateMutationOptions = Apollo.BaseMutationOptions<LabelCreateMutation, LabelCreateMutationVariables>;
export const CardCreateDocument = gql`
    mutation cardCreate {
  cardCreate(
    CardCreateArgs: {project: "e7d1d878-0af2-46eb-b49f-b6483593ebb7", count: 100}
  ) {
    ... on CardList {
      list {
        number
        code
      }
    }
    ... on ValidationErrors {
      ...ValidationErrors
    }
  }
}
    ${ValidationErrorsFragmentDoc}`;
export type CardCreateMutationFn = Apollo.MutationFunction<CardCreateMutation, CardCreateMutationVariables>;
export function useCardCreateMutation(baseOptions?: Apollo.MutationHookOptions<CardCreateMutation, CardCreateMutationVariables>) {
        return Apollo.useMutation<CardCreateMutation, CardCreateMutationVariables>(CardCreateDocument, baseOptions);
      }
export type CardCreateMutationHookResult = ReturnType<typeof useCardCreateMutation>;
export type CardCreateMutationResult = Apollo.MutationResult<CardCreateMutation>;
export type CardCreateMutationOptions = Apollo.BaseMutationOptions<CardCreateMutation, CardCreateMutationVariables>;
export const AccountProjectsDocument = gql`
    query accountProjects {
  account {
    projects {
      list {
        ...Project
      }
    }
  }
}
    ${ProjectFragmentDoc}`;
export function useAccountProjectsQuery(baseOptions?: Apollo.QueryHookOptions<AccountProjectsQuery, AccountProjectsQueryVariables>) {
        return Apollo.useQuery<AccountProjectsQuery, AccountProjectsQueryVariables>(AccountProjectsDocument, baseOptions);
      }
export function useAccountProjectsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AccountProjectsQuery, AccountProjectsQueryVariables>) {
          return Apollo.useLazyQuery<AccountProjectsQuery, AccountProjectsQueryVariables>(AccountProjectsDocument, baseOptions);
        }
export type AccountProjectsQueryHookResult = ReturnType<typeof useAccountProjectsQuery>;
export type AccountProjectsLazyQueryHookResult = ReturnType<typeof useAccountProjectsLazyQuery>;
export type AccountProjectsQueryResult = Apollo.QueryResult<AccountProjectsQuery, AccountProjectsQueryVariables>;
export const ProjectCardsDocument = gql`
    query projectCards($id: ID!) {
  project(id: $id) {
    ...Project
    cards {
      list {
        ...Card
      }
    }
  }
}
    ${ProjectFragmentDoc}
${CardFragmentDoc}`;
export function useProjectCardsQuery(baseOptions: Apollo.QueryHookOptions<ProjectCardsQuery, ProjectCardsQueryVariables>) {
        return Apollo.useQuery<ProjectCardsQuery, ProjectCardsQueryVariables>(ProjectCardsDocument, baseOptions);
      }
export function useProjectCardsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ProjectCardsQuery, ProjectCardsQueryVariables>) {
          return Apollo.useLazyQuery<ProjectCardsQuery, ProjectCardsQueryVariables>(ProjectCardsDocument, baseOptions);
        }
export type ProjectCardsQueryHookResult = ReturnType<typeof useProjectCardsQuery>;
export type ProjectCardsLazyQueryHookResult = ReturnType<typeof useProjectCardsLazyQuery>;
export type ProjectCardsQueryResult = Apollo.QueryResult<ProjectCardsQuery, ProjectCardsQueryVariables>;
export const CardDocument = gql`
    query card($id: ID!) {
  card(id: $id) {
    number
    code
  }
}
    `;
export function useCardQuery(baseOptions: Apollo.QueryHookOptions<CardQuery, CardQueryVariables>) {
        return Apollo.useQuery<CardQuery, CardQueryVariables>(CardDocument, baseOptions);
      }
export function useCardLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CardQuery, CardQueryVariables>) {
          return Apollo.useLazyQuery<CardQuery, CardQueryVariables>(CardDocument, baseOptions);
        }
export type CardQueryHookResult = ReturnType<typeof useCardQuery>;
export type CardLazyQueryHookResult = ReturnType<typeof useCardLazyQuery>;
export type CardQueryResult = Apollo.QueryResult<CardQuery, CardQueryVariables>;
export const CardByCodeDocument = gql`
    query cardByCode($code: String!) {
  cardByCode(code: $code) {
    ...Card
  }
}
    ${CardFragmentDoc}`;
export function useCardByCodeQuery(baseOptions: Apollo.QueryHookOptions<CardByCodeQuery, CardByCodeQueryVariables>) {
        return Apollo.useQuery<CardByCodeQuery, CardByCodeQueryVariables>(CardByCodeDocument, baseOptions);
      }
export function useCardByCodeLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CardByCodeQuery, CardByCodeQueryVariables>) {
          return Apollo.useLazyQuery<CardByCodeQuery, CardByCodeQueryVariables>(CardByCodeDocument, baseOptions);
        }
export type CardByCodeQueryHookResult = ReturnType<typeof useCardByCodeQuery>;
export type CardByCodeLazyQueryHookResult = ReturnType<typeof useCardByCodeLazyQuery>;
export type CardByCodeQueryResult = Apollo.QueryResult<CardByCodeQuery, CardByCodeQueryVariables>;
export const CardSetLabelDocument = gql`
    mutation cardSetLabel($args: CardSetLabelArgs!) {
  cardSetLabel(CardSetLabelArgs: $args) {
    ... on Card {
      ...Card
    }
    ... on ValidationErrors {
      ...ValidationErrors
    }
  }
}
    ${CardFragmentDoc}
${ValidationErrorsFragmentDoc}`;
export type CardSetLabelMutationFn = Apollo.MutationFunction<CardSetLabelMutation, CardSetLabelMutationVariables>;
export function useCardSetLabelMutation(baseOptions?: Apollo.MutationHookOptions<CardSetLabelMutation, CardSetLabelMutationVariables>) {
        return Apollo.useMutation<CardSetLabelMutation, CardSetLabelMutationVariables>(CardSetLabelDocument, baseOptions);
      }
export type CardSetLabelMutationHookResult = ReturnType<typeof useCardSetLabelMutation>;
export type CardSetLabelMutationResult = Apollo.MutationResult<CardSetLabelMutation>;
export type CardSetLabelMutationOptions = Apollo.BaseMutationOptions<CardSetLabelMutation, CardSetLabelMutationVariables>;
export const AccountDocument = gql`
    query account {
  account {
    ...Account
  }
}
    ${AccountFragmentDoc}`;
export function useAccountQuery(baseOptions?: Apollo.QueryHookOptions<AccountQuery, AccountQueryVariables>) {
        return Apollo.useQuery<AccountQuery, AccountQueryVariables>(AccountDocument, baseOptions);
      }
export function useAccountLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AccountQuery, AccountQueryVariables>) {
          return Apollo.useLazyQuery<AccountQuery, AccountQueryVariables>(AccountDocument, baseOptions);
        }
export type AccountQueryHookResult = ReturnType<typeof useAccountQuery>;
export type AccountLazyQueryHookResult = ReturnType<typeof useAccountLazyQuery>;
export type AccountQueryResult = Apollo.QueryResult<AccountQuery, AccountQueryVariables>;