export class AuthService<T, P> {
    private readonly key
    private cachedAccount: T | null = null
    private onLoginAction: (account: T, params: P) => unknown = () => {}
    private onLogoutAction: (params: P) => unknown = () => {}

    constructor(key: string) {
        this.key = key
    }

    get account(): T | null {
        if (this.cachedAccount) {
            return this.cachedAccount
        }
        const account = localStorage.getItem(this.key)
        return account ? JSON.parse(account) : null
    }

    public login(account: T, params: P) {
        this.cachedAccount = account
        localStorage.setItem(this.key, JSON.stringify(account))
        this.onLoginAction(account, params)
        return account
    }

    public logout(params: P) {
        this.cachedAccount = null
        localStorage.removeItem(this.key)
        this.onLogoutAction(params)
    }

    public onLogin(listener: (account: T) => unknown) {
        this.onLoginAction = listener
        return this
    }
    public onLogout(listener: () => unknown) {
        this.onLogoutAction = listener
        return this
    }
}