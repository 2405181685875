import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import Link from '@material-ui/core/Link';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';

interface ButtonOptions { disabled?: boolean, link?: { target?: 'blank', href: string }, onClick?: () => void, title: string, color?: "inherit" | "primary" | "secondary" }
interface Props { onClose: () => void,  open: boolean, title: string, buttons: Array<ButtonOptions> }

export const AlertDialog: React.FC<Props> = ({ children, onClose, open, title, buttons }) => {
    const renderButton = (key: string | number, b: ButtonOptions) => (<Button key={key} disabled={b.disabled} color={b.color} onClick={b.onClick} >{b.title}</Button>)
    return (
            <Dialog
                open={open}
                onClose={onClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                transitionDuration={0}
            >
                <DialogTitle id="alert-dialog-title">{title}</DialogTitle>
                <DialogContent>
                    {children}
                </DialogContent>
                <DialogActions>
                    {buttons.map((b, key) => {
                        return b.link
                            ? (<Link key={`link_${key}`} download target={b.link.target}
                                     href={b.link.href}>{renderButton(key, b)}</Link>)
                            : renderButton(key, b)
                    }
                    )}
                </DialogActions>
            </Dialog>
    );
}