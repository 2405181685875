export enum Routes {
    ProjectCards = '/dashboard/projects/:projectId/cards',
    Projects = '/dashboard/projects',
    Dashboard = '/dashboard',
    Login = '/login'
}

export const Paths: Record<Routes, (...args: any[]) => string> = {
    [Routes.Dashboard]: () => Routes.Dashboard,
    [Routes.Projects]: () => Routes.Projects,
    [Routes.ProjectCards]: (projectId: string) => Routes.ProjectCards.replace(':projectId', projectId),
    [Routes.Login]: () => Routes.Login,
}