import React, {useState} from 'react'
import Avatar from '@material-ui/core/Avatar'
import Button from '@material-ui/core/Button'
import TextField from '@material-ui/core/TextField'
import Typography from '@material-ui/core/Typography'
import {makeStyles} from '@material-ui/core/styles'
import {useAccountLoginMutation} from './generated/graphql'
import {useSnackbar} from './context/SnackbarContext'
import {Page} from './layout/Page'
import { useAuth } from './context/AuthContext'


const useStyles = makeStyles((theme) => ({
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));

export default function SignIn() {
  const classes = useStyles();
  const [password, setPassword] = useState('');
  const { setMessage } = useSnackbar()
  const auth = useAuth()

  const [login, result] = useAccountLoginMutation({
    onCompleted: ({ accountLogin }) => {
      if (accountLogin.__typename === 'ValidationErrors') {
        setMessage(accountLogin, 'error')
        return
      }
      if(accountLogin.__typename === 'Authenticator') {
        auth.login(accountLogin.account)
        return
      }
    },
    onError: setMessage
  });

  const identifier = 'suedstadt-apotheke'

  return (
    <Page title={'Login'}>
        <Avatar className={classes.avatar} />
        <Typography component="h1" variant="h5">
          Sign in
        </Typography>
        <form className={classes.form} noValidate>
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            id="identifier"
            label="Account"
            name="Account"
            autoFocus
            disabled
            value={identifier}
          />
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            name="password"
            label="Password"
            type="password"
            id="password"
            autoComplete="current-password"
            onChange={(event) => setPassword(event.target.value)}
          />
          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            className={classes.submit}
            onClick={(e) => {
              e.preventDefault();
              login({ variables: { args: { password, identifier }}})
            }}
            disabled={result.loading || password.length === 0}
          >
            Anmelden
          </Button>
        </form>
    </Page>
  );
}