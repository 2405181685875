import { useMemo, useState } from "react"
import {useHistory } from "react-router-dom";
import { AccountFragment} from "../generated/graphql";
import {AuthContext} from "./AuthContext";
import {AuthService} from "../api/auth";
import { useApolloClient } from "@apollo/client";


export const ProvideAuth: React.FC = ({ children }) => {
    const [account, setAccount] = useState<AccountFragment | null>(null);
    const history = useHistory()
    const client = useApolloClient()

    const auth = useMemo(() => {
        return new AuthService<AccountFragment, string | undefined>('account')
            .onLogin((a, to = '/') => {
                setAccount(account)
                history.replace(to);
            })
            .onLogout((to = '/') => {
                setAccount(null)
                history.replace(to);
                client.clearStore()
            })
    }, [ account, history, client ])


    return (
        <AuthContext.Provider value={auth}>
            {children}
        </AuthContext.Provider>
    );
}