import React, {useState} from 'react';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import {FileFragment, useAccountProjectsQuery, useProjectDownloadProjectCardsMutation} from "./generated/graphql";
import {isQueryLoaded} from "./utils";
import Dashboard from "./Dashboard";
import {useSnackbar} from "./context/SnackbarContext";
import NavigateNext from '@material-ui/icons/NavigateNext';
import GetApp from '@material-ui/icons/GetApp';
import DownloadDialog from './DownloadDialog';
import { useHistory } from 'react-router-dom';
import {Paths} from "./context/routes";
import {Breadcrumbs, Button, makeStyles, Typography } from '@material-ui/core';



const useStyles = makeStyles((theme) => ({
    downloadButton: {
        marginLeft: theme.spacing(1),
    },
    breadcrumbs: {
        margin: theme.spacing(1),
    }
}));

export default function Projects() {
    const classes = useStyles()
    const [download, setDownload] = useState<FileFragment | null>(null);
    const result = useAccountProjectsQuery()
    const { setMessage } = useSnackbar()
    const history = useHistory()
    const [projectDownloadProjectCards] = useProjectDownloadProjectCardsMutation({
        onCompleted: ({ projectDownloadProjectCards: result }) => {
            if (result.__typename === 'ValidationErrors') {
                setMessage(result, 'error')
                return
            }
            if(result.__typename === 'File') {
                setDownload(result)
                return
            }
        },
        onError: setMessage,
    })

    const projects = isQueryLoaded(result) ? result.data.account.projects.list : []

    return (
        <Dashboard>
        <React.Fragment>
            <Breadcrumbs className={classes.breadcrumbs} aria-label="breadcrumb">
                <Typography color="textPrimary">Alle Testtage</Typography>
            </Breadcrumbs>
            <Table size="small">
                <TableHead>
                    <TableRow>
                        <TableCell>Name</TableCell>
                        <TableCell>Description</TableCell>
                        <TableCell align={'right'}>Auswertung | PDF Download</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {projects.map((p) => (
                        <TableRow key={p.id}>
                            <TableCell>{p.name}</TableCell>
                            <TableCell>{p.description || ''}</TableCell>
                            <TableCell align={'right'}>
                                <Button variant="contained" color="primary" onClick={() => history.push(Paths['/dashboard/projects/:projectId/cards'](p.id))}>
                                    Auswertung
                                    <NavigateNext />
                                </Button>
                                <Button className={classes.downloadButton} variant="outlined" color="primary" onClick={() => projectDownloadProjectCards({
                                    variables: { id: p.id },
                                })}>
                                    PDF
                                    <GetApp />
                                </Button>
                            </TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
            {download && <DownloadDialog onClose={() => setDownload(null)} open={true} file={download} />}
        </React.Fragment>
        </Dashboard>
    );
}
