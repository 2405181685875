import React from 'react';
import clsx from 'clsx';
import {makeStyles} from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import Badge from '@material-ui/core/Badge';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import ExitToApp from '@material-ui/icons/ExitToApp';

import {Dashboard as DashboardLayout} from "./layout/Dashboard";
import {useAuth} from "./context/AuthContext";
import {useAccountLogoutMutation, useAccountQuery} from "./generated/graphql";
import {useSnackbar} from "./context/SnackbarContext";

const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  toolbar: {
    paddingRight: 24, // keep right padding when drawer closed
  },
  toolbarIcon: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: '0 8px',
    ...theme.mixins.toolbar,
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: 36,
  },
  menuButtonHidden: {
    display: 'none',
  },
  title: {
    flexGrow: 1,
  },
  drawerPaper: {
    position: 'relative',
    whiteSpace: 'nowrap',
    width: drawerWidth,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerPaperClose: {
    overflowX: 'hidden',
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    width: theme.spacing(7),
    [theme.breakpoints.up('sm')]: {
      width: theme.spacing(9),
    },
  },
  appBarSpacer: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
    height: '100vh',
    overflow: 'auto',
  },
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
  paper: {
    padding: theme.spacing(2),
    display: 'flex',
    overflow: 'auto',
    flexDirection: 'column',
  },
  fixedHeight: {
    height: 240,
  },
}));

const Dashboard: React.FC = (props) => {
  const classes = useStyles();
  // const [open, setOpen] = React.useState(false);
  const open = false
  const auth = useAuth()
  const { setMessage } = useSnackbar()
  useAccountQuery({
    fetchPolicy: 'network-only',
    errorPolicy: 'ignore',
    pollInterval: 60000,
  })

  const [logout] = useAccountLogoutMutation({
    onCompleted: () => auth.logout(),
    onError: setMessage
  })

  // const handleDrawerOpen = () => {
  //   setOpen(true);
  // };
  // const handleDrawerClose = () => {
  //   setOpen(false);
  // };

  return (
      <DashboardLayout>
        <AppBar position="absolute" className={clsx(classes.appBar, open && classes.appBarShift)}>
          <Toolbar className={classes.toolbar}>
            {/*<IconButton*/}
            {/*    edge="start"*/}
            {/*    color="inherit"*/}
            {/*    aria-label="open drawer"*/}
            {/*    onClick={handleDrawerOpen}*/}
            {/*    className={clsx(classes.menuButton, open && classes.menuButtonHidden)}*/}
            {/*>*/}
            {/*  <MenuIcon />*/}
            {/*</IconButton>*/}
            <Typography component="h1" variant="h6" color="inherit" noWrap className={classes.title}>
              {auth.account ? auth.account.name : null}
            </Typography>
            <IconButton color="inherit" onClick={() => logout()}>
              <Badge color="secondary">
                <ExitToApp />
              </Badge>
            </IconButton>
          </Toolbar>
        </AppBar>
        {/*<Drawer*/}
        {/*    variant="permanent"*/}
        {/*    classes={{*/}
        {/*      paper: clsx(classes.drawerPaper, !open && classes.drawerPaperClose),*/}
        {/*    }}*/}
        {/*    open={open}*/}
        {/*>*/}
        {/*  <div className={classes.toolbarIcon}>*/}
        {/*    <IconButton onClick={handleDrawerClose}>*/}
        {/*      <ChevronLeftIcon />*/}
        {/*    </IconButton>*/}
        {/*  </div>*/}
        {/*  <Divider />*/}
        {/*  <List><MainListItems /></List>*/}
        {/*  <Divider />*/}
        {/*</Drawer>*/}
        <main className={classes.content}>
          <div className={classes.appBarSpacer} />
          <Container maxWidth="lg" className={classes.container}>
            {/*<Breadcrumbs aria-label="breadcrumb">*/}
            {/*  <Link color="inherit" href="/" onClick={() => {}}>*/}
            {/*    Material-UI*/}
            {/*  </Link>*/}
            {/*  <Link color="inherit" href="/getting-started/installation/" onClick={() => {}}>*/}
            {/*    Core*/}
            {/*  </Link>*/}
            {/*  <Typography color="textPrimary">Breadcrumb</Typography>*/}
            {/*</Breadcrumbs>*/}
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <Paper className={classes.paper}>
                  {props.children}
                </Paper>
              </Grid>
            </Grid>
          </Container>
        </main>
      </DashboardLayout>
  );
}

export default Dashboard