import { ApolloClient, InMemoryCache, from } from '@apollo/client'
import { ErrorLink } from "@apollo/client/link/error";
import { HttpLink } from "@apollo/client/link/http";
import fragments from "../generated/fragments";

// const uri = 'https://localhost:8000/api/v1/'
// const uri = 'https://4m6xaj2v78.execute-api.eu-central-1.amazonaws.com/prod/api/v1/'
const uri = 'https://api.testergebnis.at/api/v1/'

export const client = new ApolloClient({
  uri,
  cache: new InMemoryCache({
    possibleTypes: fragments.possibleTypes,
  }),
  link: from([
    new ErrorLink(({ graphQLErrors, networkError }) => {
      if (graphQLErrors)
        graphQLErrors.map(({ message, locations, path }) =>
            console.log(
                `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`,
            ),
        );

      if (networkError) console.log(`[Network error]: ${networkError}`);
    }),
    new HttpLink({ uri, credentials: 'include' }),
  ]),
})

