import {CssBaseline, makeStyles} from "@material-ui/core";
import React from "react";
import {MessageBox} from "./MessageBox";

const useStyles = makeStyles(() => ({
    paper: {
        display: 'flex',

    },
}));

export const Dashboard: React.FC = ({ children }) => {
    const classes = useStyles()

    return (
        <div className={classes.paper}>
            <CssBaseline />
            {children}
            <MessageBox />
        </div>
        )
}