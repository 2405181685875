import React from 'react';
import {File,} from "./generated/graphql";
import {DialogContentText} from '@material-ui/core';
import {AlertDialog} from "./components/AlertDialog";


const DownloadDialog: React.FC<{ file: File, onClose: () => unknown, open: boolean }> = ({ file, onClose, open }) => {
    return (
            <AlertDialog
                onClose={onClose} open={open} title={`Download`} buttons={[{
                color: "secondary",
                title: 'Schließen',
                onClick: () => onClose(),
            },
            {
                color: "primary",
                title: 'Herunterladen',
                link: {
                    href: file.url,
                    target: 'blank',
                },
            }
            ]} >
                <DialogContentText><p>Wollen sie die Datei {file.name} herunterladen?</p></DialogContentText>
            </AlertDialog>
    );
}

export default DownloadDialog