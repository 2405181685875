import React from 'react';

import Tests from "./Tests";
import Dashboard from "./Dashboard";
import {useParams} from 'react-router-dom';


const ProjectCards: React.FC = () => {
  const { projectId }  = useParams<{ projectId: string }>()

  return (<Dashboard>
      <Tests projectId={projectId}/>
  </Dashboard>)
}

export default ProjectCards