import {createContext, useContext} from "react";
import {AccountFragment} from "../generated/graphql";


interface Context<T> {
    account: T | null,
    login: (account: T, to?: string) => T
    logout: (to?: string) => void
}

export const AuthContext = createContext<Context<AccountFragment>>({
    account: null,
    login: a => a,
    logout: () => {},
});


export function useAuth() {
    return useContext(AuthContext);
}
